<template>
  <div class="research container col_">
    <section class="one">
      <v-row align="start">
        <v-col cols="12" md="7" class="left">
          <h1 class="title-1 animated fadeInUp">
            {{ $t("research.title") }}
          </h1>
          <p class="content animated fadeInUp">
            {{ $t("research.content") }}
          </p>
        </v-col>
        <v-col cols="12" md="5" class="right animated fadeInUp">
          <h3>{{ $t("research.right.one") }}</h3>
          <p >{{ $t("research.right.tow") }}</p>
          <p class="p20">{{ $t("research.right.three") }}</p>
          <p class="p20">{{ $t("research.right.four") }}</p>
          <p class="p20">{{ $t("research.right.five") }}</p>
          <p class="p20">{{ $t("research.right.six") }}</p>
          <p class="p20">{{ $t("research.right.seven") }}</p>
        </v-col>
      </v-row>
    </section>
    <section class="animated fadeInUp">
      <div class="nb" style="width: 65%"></div>
    </section>

    <section class="two col_">
      <div class="top">
        {{ $t("research.papers") }} & {{ $t("research.blogs") }}
      </div>

      <v-container fluid class="my-14">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="item"
            v-for="(item, index) in Papers"
            :key="index"
          >
            <v-card class="x-card">
              <v-responsive :aspect-ratio="414 / 180">
                <v-img :src="item.ims" :alt="$t(item.title)" />
              </v-responsive>
              <div class="body">
                <v-card-title
                  class="black--text"
                  v-text="$t(item.title)"
                ></v-card-title>
              </div>

              <v-card-actions>
                <v-btn :href="item.link" text target="_blank" color="#b3b3b3">
                  {{$t("research.click")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- <section class="three">
        <div class="top">{{$t('research.bottom.head')}}</div>
        <div class="btm row">
            <div class="item col" v-for="item of 7" :key="item">
                <div class="head"></div>
                <div class="name">
                    <p>{{$t('research.bottom.title')}}</p>
                    <p style="color:#ccc">{{$t('research.bottom.content')}}</p>
                </div>
                <div class="introduce"></div>
            </div>
        </div>
    </section> -->
    <ButtomNav></ButtomNav>
  </div>
</template>

<script>
import ButtomNav from "@/components/buttomNav.vue";
export default {
  components: {
    ButtomNav,
  },
  name: "research",
  data() {
    return {
      Papers: [
        {
          title: "research.PapersTitle.one",
          link: "https://files.phala.network/phala-paper.pdf",
          ims: require("../assets/img/slices-five.png"),
        },
        {
          title: "research.PapersTitle.tow",
          link: "https://transx.io/TransX_Whitepaper_zh.pdf",
          ims: require("../assets/img/slices-one.png"),
        },
        {
          title: "research.PapersTitle.three",
          link: "https://listen.io/static/listen-white-paper-1.0-zh.pdf",
          ims: require("../assets/img/slices-tow.png"),
        },
        {
          title: "research.PapersTitle.four",
          link: "/pdf/ibo_whitepaper.pdf",
          ims: require("../assets/img/slices-therr.png"),
        },
        {
          title: "research.PapersTitle.five",
          link: "/pdf/dico_whitepaper.pdf",
          ims: require("../assets/img/slices-four.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.research {
  width: 100%;
  > section {
    width: 100%;
  }
  .one {
    margin: 5vh 0 15vh;
    //   background: #eee;
    .left {
      padding-right: 30vh;
      > h1 {
        margin-bottom: 7.5vh;
        font-size: 2em;
      }
      > p {
        line-height: 1.4;
        max-width: 1034px;
        font-size: 30px;
        margin-bottom: 5vh;
        font-weight: 600;
      }
      > a {
        font-size: 0.9em;
        span + span {
          font-size: 1.5em;
          padding-left: 0.5vw;
        }
      }
      > a:hover > span + span {
        padding-left: 1vw;
        transition: all ease 0.3s;
      }
    }
  }
  .right {
    background: #fff;
    box-sizing: border-box;
    max-width: 52vw;
    padding: 40px;
    line-height: 1.4;
    color: #000;
    > h3 {
      margin-bottom: 6vh;
    }
    > p {
      margin-bottom: 3vh;
      font-size: 14px;
    }
    > p + p {
      margin-bottom: 1vh;
    }
  }
  > .two {
    margin-top: 10vh;
    padding: 10vh 2vw 10vh;
    // background: #EDEDED;
    > .div {
      width: 100%;
    }
    .x-card {
      // min-height: 18vw;
      margin: 2vw 1vw;
      .body {
        min-height: 90px;
      }
    }
    > .top {
      font-size: 1.3vw;
      font-weight: 700;
      margin-left: 2vw;
    }
    > .down {
      padding-left: 8vw;
      // background: #aaa;
      margin-top: 10vh;
      > .item {
        width: 27%;
        margin-right: 4vw;
        margin-bottom: 10vh;
        // box-shadow: 0 0 4vw #aaa;
        > .top {
          width: 100%;
          height: 20vh;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        > .btm {
          padding: 4vh 1vw;
          background: #fff;
          > .text {
            font-size: 1em;
            color: #000;
            margin-bottom: 4vh;
          }
          hr {
            width: 100%;
            border-bottom: 0;
            margin: 5vh 0 3vh;
            color: #b3b3b3;
          }
          > a {
            width: 100%;
            font-size: 0.9em;
            span + span {
              font-size: 1.5em;
              padding-left: 0.5vw;
              transition: all ease 0.3s;
            }
          }
          > a:hover > span + span {
            padding-left: 1vw;
          }
        }
      }
    }
  }
  > .three {
    margin-top: 15vh;
    > .top {
      font-size: 1.3vw;
      font-weight: 700;
      margin-left: 2vw;
    }
    > .btm {
      width: 100%;
      padding: 5vh 0;
      padding-left: 10vw;
      // background: #aaa;
      margin-top: 10vh;
      > .item {
        width: 14vw;
        // background: #999;
        margin-right: 6vw;
        margin-bottom: 7vh;
        > .head {
          width: 12vw;
          height: 12vw;
          background: #eee;
          border-radius: 50%;
        }
        > .name {
          font-size: 1.1em;
          margin-top: 3vh;
        }
      }
    }
  }
  .p20 {
    margin-bottom: 20px;
    margin-left: 20px;
    &:before {
      content: "\2014";
      position: absolute;
      left: 40px;
      color: black;
    }
  }
}

@media screen and (max-width: 940px) {
  // 移动设备
  .research {
    .one {
      margin-top: 10vh;
      .left {
        padding-right: 0;
        > .title {
          font-size: 8vw;
          margin-bottom: 3vh;
        }
        > .content {
          font-size: 8vw;
        }
      }
      .right {
        max-width: 100vw;
        padding: 1.5vh 4vw;
        margin-top: 3.5vh;
        > h3 {
          font-size: 5vw;
          margin-bottom: 1vh;
        }
        margin-bottom: 0;
        > p {
          margin-top: 2vh;
          margin-bottom: 0;
        }
        .p20 {
          margin-left: 20px;
          &:before {
            left: 20px;
          }
        }
      }
    }
    .two {
      margin: 0;
      padding: 3vh 0;
      > .top {
        font-size: 7vw;
      }
      > .down {
        margin: 0;
        padding: 5vh 2vw;
        > .item {
          width: 100%;
          margin: 0 0 3vh 0;
        }
      }
    }
    .three {
      margin-top: 4vh;
      > .top {
        font-size: 6.5vw;
      }
      > .btm {
        margin-top: 2vh;
        padding: 0;
        > .item {
          width: 100%;
          // background: #ff0;
          margin: 0 0 3vh 0;
          > .head {
            width: 60vw;
            height: 60vw;
          }
          > div + div {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
